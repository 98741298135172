@import '../../App.scss';

.about-container {
    display: grid;
    grid-template:
        "header header header" 125px
        "content content content" minmax(250px, 1fr)
        "footer footer footer" 125px
        / 1fr minmax(300px, 1fr) minmax(400px, 1fr)
    ;

    min-height: 100vh;
    width: 100vw;

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 1;
        grid-area: content;

        .content-container {
            align-self: center;
            display: flex;
            flex-direction: column;
            .content-header-row {
                display: flex;
                flex-direction: row;
                justify-content: center;

                .content-header {
                    color: $primary-light;
                    font-size: $header-text-size;
                    font-weight: 500;
                    margin: 0 5px 0 5px;
                    cursor: pointer;
                }

                .content-header:hover {
                    color: $primary100;
                }
            }

            .section-container {
                align-self: center;
                width: 500px;

                .section-header {
                    margin: 10px 0 0 0;

                    font-size: $content-text-size-2;
                    font-weight: 500;
                    color: $primary-light;
                    opacity: 0.9;
                }
                
                .section-text {
                    font-size: $content-text-size;
                    font-weight: 500;
                    color: $primary-light;
                    margin: 5px 0 0 0;
                }

                .inspo-link {
                    color: $primary-light;
                    text-decoration: none;
                    margin: 10px 10px 0 0px;
                }

                .roadmap-link {
                    text-decoration: none;
                    color: $primary200;
                    cursor: pointer;
                }

                .roadmap-link:hover {
                    font-weight: 600;
                }

                .contact-icon-row {
                    margin: 10px 0 0 0;
                    display: flex;
                    flex-direction: row;

                    .contact-icon {
                        width: 24px;
                        height: 24px;
                        fill: none;
                        stroke: $secondary-light;
                    }
                }
            }
        }
    }

    .footer {
        grid-area: footer;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

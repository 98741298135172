@import './styles/colors.scss';
@import './styles/typography.scss';

// STYLES FOR THEMING PURPOSES
.text {
  font-family: $font-family;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

// STYLES FOR APP.TSX
.container {
  background: none;
  
  min-height: 500px;
  min-width: 700px;

  transition: 2s linear;
}

.bubble {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: fixed;
  z-index: 0;
}

.contact-icon {
  width: 32px;
  height: 32px;
  fill: none;
  stroke: $secondary-light;
}

.toast-div {
  display: flex;
  align-items: center;
}

.toast-text {
  margin-left: 10px;
  font-size: $text-size-small;
}

@keyframes move-background-shape-0 {
  100% {
    -webkit-transform: translate3d(0, 0, 0) rotate(360deg);
    transform: translate3d(0, 0, 0) rotate(360deg);
  }
}

@keyframes move-background-shape-1 {
  100% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-360deg);
    transform: translate3d(0, 0, 0) rotate(-360deg);
  }
}
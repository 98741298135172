@import "../../App.scss";

.stats-container {
    z-index: 1;
    display: grid;
    grid-template:
      "header header header" 125px
      "content content content" minmax(250px, 1fr)
      "footer footer footer" 125px
      / 1fr minmax(300px, 1fr) minmax(400px, 1fr)
    ;

    min-height: 100vh;
    width: 100vw;

    .content-container {
        grid-area: content;
        place-content: center;
        display: flex;
        z-index: 1;

        .reset-button {
            align-self: center;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            height: 30px;
            width: 150px;
            background-color: $primary800;
            border-radius: 20px;

            box-shadow: 0px 0px 6px 1px $primary200;
            cursor: pointer;
            transition: 0.3s linear;

            .button-text {
                margin: 0;
                font-weight: 400;
                color: $primary-light;
            }
        }

        .reset-button:hover {
            transform: scale(1.1);
        }

        .stats-content-container {
            display: flex;
            flex-direction: column;

            .stats-title {
                align-self: center;
                color: $primary-light;
                font-size: $header-text-size; 
                font-weight: 500;
                margin: 0 0 5px 0;
            }

            .stats-section {
                align-self: center;
                width: 650px;

                .stat-col {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 5px;

                    .status {
                        margin-top: 0;
                        margin-bottom: 0;
                        color: $primary-light;
                        font-weight: 600;
                    }

                    .stat {
                        margin-top: 0;
                        margin-bottom: 0;
                        color: $primary-light;
                    }
                }
            }
        }
    }

    .footer {
        grid-area: footer;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
@import "../../App.scss";

.loader-mask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 4;
    position: fixed;
    height: 100%;
    width: 100%;

    background: $dark-grey-1;
    opacity: 0.9;

    .loader-text {
        color: $primary-light;
        font-weight: 600;
        font-size: $content-text-size-1;
    }
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 32px;
    background: #fff;
    animation: lds-facebook 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 16px;
    animation-delay: -0.56s;
  }
  .lds-facebook div:nth-child(2) {
    left: 64px;
    animation-delay: -0.28s;
  }
  .lds-facebook div:nth-child(3) {
    left: 112px;
    animation-delay: -0.04s;
  }
  @keyframes lds-facebook {
    0% {
      top: 16px;
      height: 128px;
    }
    50%, 100% {
      top: 48px;
      height: 64px;
    }
  }
  
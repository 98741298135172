@import '../../App.scss';

.contact-container {
    display: grid;
    grid-template:
        "header header header" 125px
        "content content content" minmax(250px, 1fr)
        "footer footer footer" 125px
        / 1fr minmax(300px, 1fr) minmax(400px, 1fr)
    ;

    min-height: 100vh;
    width: 100vw;

    .contact-content-container {
        padding: 50px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-area: content;

        .text {
            margin: 0 0 0 0;
            color: $primary-light;
        }

        .header-text {
            margin: 10px 0 0 0;

            font-size: $content-text-size-2;
            font-weight: 500;
            color: $primary-light;
            opacity: 0.9;
        }

        .contact-icon {
            width: 32px;
            height: 32px;
            fill: none;
            stroke: $secondary-light;
        }
    }
}
@import "../../App.scss";

.dialog-exit-icon {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.dialog-exit-icon:hover {
    fill: $primary300;
    stroke: $primary800;
}

.pomodoro-settings-dialog-mask {
    position: fixed;
    height: 100%;
    width: 100%;
  
    min-height: 500px;
    min-width: 700px;

    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.925;

    background-color: $primary500;
    cursor: pointer;
}

.pomodoro-settings-dialog {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;

    width: 400px;
    height: 500px;
    margin-top: -250px;
    margin-left: -200px;

    border-radius: 10px;

    .save-button {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        height: 30px;
        width: 100px;
        background-color: $primary800;
        border-radius: 20px;

        box-shadow: 0px 0px 6px 1px $primary200;
        cursor: pointer;
        transition: 0.3s linear;

        .button-text {
            margin: 0;
            font-weight: 500;
            color: $primary-light;
        }
    }

    .save-button:hover {
        transform: scale(1.1);
    }

    .centered {
        margin-left: auto;
        margin-right: auto;
    }

    .pomodoro-settings-divider {
        width: 385px;
        border-bottom: 1px solid $dark-grey-2;
        align-self: center;
    }
    
    .pomodoro-settings-header-row {
        padding: 5px;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .pomodoro-settings-header {
            color: $primary-light;
            font-size: $header-text-size; 
            font-weight: 500;
            margin: 0 0 5px 0;
        }
    }

    .radio-button {
        height: 18px;
        width: 18px;
        border: 2px solid $primary200;
        border-radius: 50%;
        margin: 0 5px 0 10px;
        cursor: pointer;
    }

    .radio-button-complete {
        height: 18px;
        width: 18px;
        border: 2px solid $primary200;
        background-color: $primary200;
        border-radius: 50%;
        margin: 0 5px 0 10px;
        cursor: pointer;
    }

    .pomodoro-settings-row {
        margin-top: 5px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        width: 100%;
    }

    .pomodoro-settings-row-radio-button {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        width: 100%;
    }
    
    .pomodoro-settings-text {
        align-self: center;
        font-weight: 500;
        color: $primary-light;
        font-size: $content-text-size; 
        margin: 0 0 0 0;
    }

    .larger {
        font-size: $content-text-size-1;
    }

    .operator-button {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 27px;
        width: 27px;
        background-color: $primary-light;
        border-radius: 50%;
        cursor: pointer;

        .operator-icon {
            height: 22px;
            width: 22px;
            fill: none;
            stroke: $primary800;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
    }

    .operator-button:hover {
        background-color: $primary800;

        .operator-icon {
            stroke: $primary-light;
        }
    }
}
@import "../../App.scss";

.sound-dialog-mask {
    position: fixed;

    background: $primary500;
    opacity: 1;

    height: 100%;
    width: 100%;

    min-height: 500px;
    min-width: 700px;

    z-index: 3;
}

.sound-dialog-container {
    position: absolute;
    display: grid;
    grid-template:
      "sound-dialog-header sound-dialog-header" 50px
      "sound-dialog-content sound-dialog-content" auto 
      "sound-dialog-footer sound-dialog-footer" 50px;
    
    margin: 50px auto 50px auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  
    border-radius: 20px;
  
    text-align: center;
  
    min-height: 500px;
    min-width: 500px;
    height: 500px;
    width: 500px;
    z-index: 3;

    .sound-dialog-header {
        display: flex;
        align-items: center;
        grid-area: sound-dialog-header;
    
        .sound-dialog-exit-icon {
          width: 48px;
          height: 48px;
          cursor: pointer;
        }
  
        .sound-dialog-exit-icon:hover {
          fill: $primary300;
          stroke: $primary800;
        }
    
        .sound-dialog-header-text {
          margin-left: 30px;
          font-family: $font-family;
          color: $secondary-light;
          font-size: $header-text-size;
          font-weight: 400;
        }
    }

    .sound-dialog-content {
        grid-area: sound-dialog-content;
        display: flex;
        flex-direction: column;

        .sound-card-row:hover {
            background-color: hsl(228, 64%, 48%, 0.5);
            border-radius: 20px;
        }

        .sound-card-row {
            display: flex;
            align-items: center;
            padding: 5px 5px 5px 5px;
            cursor: pointer;

            .sound-card-background {
                margin-right: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
        
                height: 80px;
                width: 80px;
                border-radius: 50%;
                filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%, 0.6));
                transition: 0.2s linear;
                cursor: pointer;
            }
    
            .sound-card-background:hover {
                filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
            }

            .nature {
                background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url("../../assets/images/Nature.jpg");
                background-repeat: no-repeat;
                background-size: cover;
            }

            .nature-selected {
                background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Nature.jpg");
                filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
                background-repeat: no-repeat;
                background-size: cover;
              }

            .ambient {
                background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url("../../assets/images/Cafe.jpg");
                background-repeat: no-repeat;
                background-size: cover;
            }

            .ambient-selected {
                background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Cafe.jpg");
                filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
                background-repeat: no-repeat;
                background-size: cover;
            }

            .meditation {
              background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url("../../assets/images/Meditation.jpg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }

            .meditation-selected {
              background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Meditation.jpg");
              filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }

            .sound-card-text-column {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
      
                .sound-card-text {
                  font-size: $header-text-size;
                  font-weight: 600;
          
                  color: $secondary-light;
                  margin: 35px 0 5px 0;
                  line-height: 0;
                }
      
                .sound-card-text-subtitle {
                  font-size: $content-text-size;
                  font-weight: 600;
                  color: $grey-primary;
                  line-height: 20px;
                }
            }  
        }
    }
    .sound-dialog-footer {
        grid-area: sound-dialog-footer;
      }
}
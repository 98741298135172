@import "../../App.scss";

a {
    text-decoration: none;
}

.header {
    position: relative;
    grid-area: header;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 20px 0 20px;
    

    .header-text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title-text {
            font-family: $font-family;
            color: $secondary-light;
            font-size: $header-text-size;
            font-weight: 500;
            margin: 0;
        }

        .title-text:hover {
            cursor: pointer;
        }

        .vertical-divider {
            align-self: flex-start;
            width: 150px;
            border-top: 2px solid $secondary-light;
        }

        .menu-text-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .dark-mode-icon:hover {
                stroke: $dark-grey-1;
                fill: $dark-grey-2;
            }

            .audio-icon {
                cursor: pointer;
                fill: none;
                stroke: $secondary-light;
                stroke-width: 2;
                margin-right: 7.5px;
            }

            .audio-icon:hover {
                stroke: $primary200;
            }

            .dark-mode-icon {
                cursor: pointer;
                fill: none;
                stroke: $secondary-light;
                stroke-width: 2;
                margin-left: 5px;
            }

            .light-mode-icon:hover {
                stroke: $primary400;
            }
            
            .light-mode-icon {
                cursor: pointer;
                fill: none;
                stroke: $secondary-light;
                stroke-width: 2;
                margin-left: 5px;
            }

            .menu-text {
                font-family: $font-family;
                color: $secondary-light;
                font-size: $content-text-size;
                font-weight: 400;
                margin: 0;
                margin-right: 10px;

                cursor: pointer;
                transition: 0.2s linear;
            }

            .menu-text:hover {
                color: $primary200;
            }
        }
    }

    .dot-logo {
        min-width: 75px;
        min-height: 75px;
        height: 75px;
        width: 75px;
        border-radius: 50%;
        background-color: $primary400;
        filter: drop-shadow(0px 0px 5px $primary300);
        margin: 0 10px 0 0;
    }

    .dot-logo:hover {
        cursor: pointer;
    }

    .ripple-1 {
        position: absolute;
        background-color: $primary400;
    }

    .ripple-1-animate {
        animation: scaleIn 3s infinite linear;
    }

    .ripple-2 {
        position: absolute;
        background-color: $primary300;
    }

    .ripple-2-animate {
        animation: scaleIn 3s infinite linear;
        animation-delay: 1s;
    }

    .ripple-3 {
        position: absolute;
        background-color: $primary200;
    }

    .ripple-3-animate {
        animation: scaleIn 3s infinite linear;
        animation-delay: 2s;
    }
}

@keyframes scaleIn {
    from {
      transform: scale(1, 1);
      opacity: 1;
    }
    to {
      transform: scale(8, 8);
      opacity: 0;
      background-color: $primary-dark;
    }
  }
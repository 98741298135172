//============//
// Main Theme // 
//============//
// Basic Colors
$black: hsl(0, 0%, 0%);
$dark-grey: hsl(0, 0%, 10%);
$dark-grey-1: hsl(0, 0%, 20%);
$dark-grey-2: hsl(0, 0%, 30%);

// Primary Colors
$primary-light: hsl(207, 100%, 95%);
$primary100: hsl(209, 100%, 87%);
$primary200: hsl(209, 100%, 79%);
$primary300: hsl(208, 100%, 71%);
$primary400: hsl(209, 100%, 65%);
$primary500: hsl(210, 100%, 60%);
$primary600: hsl(212, 86%, 57%);
$primary700: hsl(215, 72%, 53%);
$primary800: hsl(218, 64%, 48%);
$primary900: hsl(225, 64%, 41%);
$primary-dark: hsl(225, 66%, 30%);

// Secondary Colors
$secondary-light: hsl(40, 100%, 98%);
$secondary: hsl(30, 100%, 90%);

// Analogous Colors
$analogous200: hsl(180, 100%, 60%);
$analogous600: hsl(183, 100%, 46%);

// Triadic Colors
$triadic-pink-light: hsl(334, 100%, 94%);

// Greys 
$grey-primary: hsl(208, 100%, 89%);






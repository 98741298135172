@import "../../App.scss";

.notes-container {
    width: 90%;
    box-shadow: 0px 4px 8px -4px black;
    border-radius: 10px;
    background: $primary-light;

    margin: 20px 0 20px 0;

    .notes-container-inner {
      display: flex;
      flex-direction: column;
      
      padding: 10px 10px 0 10px;

      border-radius: 10px;
      min-height: 85px;

      .notes-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid grey;
  
        margin: 0 0 10px 0;

        .notes-header-left {
          font-size: $subtitle-text-size;
          color: $black;
        }

        .notes-header-right {
          font-size: $subtitle-text-size;
          color: $black;
          opacity: 0.6;
          margin: 0 0 0 auto;
          cursor: pointer;
        }

        .notes-header-right:hover {
          font-weight: 500;
        }
      }

      .notes-text {
        resize: vertical;
        background-color: transparent;
        color: $black;
        appearance: none;
        border: none;
        align-self: center;
        width: 100%;
        min-height: 85px;
        font-size: $content-text-size;
      }

      .notes-text:focus {
        outline: none;
      }
    }
  }
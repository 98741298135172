@import "../../App.scss";

.pomodoro-skip-icon {
    width: 22px;
    height: 22px;
    transition: 0.2s linear;
}

.pomodoro-skip-icon:hover {
    cursor: pointer;
    stroke: $primary300;
    fill: $primary300;
}

.pomodoro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 20px 0 20px 0;

    .pomodoro-timer-row {
        display: flex;
        align-items: center;
        min-width: 400px;
        justify-content: center;
        gap: 5px;

        .pomodoro-time {
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            color: $secondary-light;
            font-size: $header-text-size-2;
            font-weight: 700;
            line-height: $header-text-size-2;
        }

        .pomodoro-icon {
            width: $pomodoro-icon-size;
            height: $pomodoro-icon-size;
            cursor: pointer;
        }

        .pomodoro-icon:hover {
            fill: $primary200;
            stroke: $primary200;
        }

        .pomodoro-timer-settings-col {
            display: flex;
            flex-direction: column;
            width: 40px;
            height: 100%;
            align-items: center;
            justify-content: center;

            .button-text {
                margin: 0;
                color: $secondary-light;
                font-weight: 600;
                cursor: pointer;
            }

            .button-text:hover {
                color: $primary200;
            }

            .restart-pomodoro-icon {
                margin-bottom: 3px;
                stroke: $secondary-light;
                fill: $secondary-light;
                cursor: pointer;
            }

            .restart-pomodoro-icon:hover {
                stroke: $primary200;
                fill: $primary200;
            }
        }
    }

    .pomodoro-cycle-row {
        margin-left: 24px;
        display: flex;
        flex-direction: row;

        align-items: center;

        .pomodoro-cycle {
            margin: 0;
            color: $secondary-light;
            font-weight: 500;
            opacity: 0.9;
        }

        .change-cycle-icon {
            fill: none;
            width: 24px;
            height: 24px;
            stroke: $secondary-light;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            cursor: pointer;
        }

        .change-cycle-icon:hover {
            stroke: $primary200;
        }
    }
}
@import "../../App.scss";

.track-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
}

.section-title {
    align-self: center;
    color: $grey-primary;
    font-weight: 600;
    font-size: $content-text-size-2;
    width: 500px;
}

.track-col:hover {
    background-color: $primary600;
    border-radius: 10px;
}

.track-col-selected {
    background-color: hsl(228, 64%, 48%, 0.5);
    border-radius: 10px;
}

.track-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2px 0 0 0;
    width: 500px;
    cursor: pointer;

    .track-title {
        padding: 5px;
        color: $secondary-light;
        font-size: $content-text-size-1;
        font-weight: 500;
    }

    .volume-slider-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        margin: 0 20px 0 20px;
        height: 48px;
        width: 400px;
        z-index: 1;
    
        .volume-slider {
            appearance: none;
            width: 100%;
            height: 12px;
            border-radius: 8px;
            background: $secondary-light;
            outline: none;
            opacity: 0.8;
            transition: opacity 0.2s;
        }
    
        .volume-slider:hover {
            opacity: 1;
        }
    
        .volume-slider::-webkit-slider-thumb {
            appearance: none;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            filter: drop-shadow(2px 2px 10px $primary900);
            background: $primary500;
            background-image: url('../../assets/icons/headphones.svg');
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
      }
    }
}

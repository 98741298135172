@import "../../App.scss";

.header-container {
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.apple-music-btn {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  background-color: white;
}

.main-container {
  display: grid;
  grid-template:
    "header header side" 125px
    "content content side" minmax(250px, 1fr)
    "footer footer side" 125px
    / 1fr minmax(300px, 1fr) minmax(400px, 1fr)
  ;

  min-height: 100vh;

  .column {
    display: grid;
    place-items: center;
  }

  .central-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .stats-button {
      cursor: pointer;
      margin: 30px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      height: 40px;
      width: 300px;
      border-radius: 10px;
      background: radial-gradient(rgba(44, 102, 201, 0.2), 	rgba(148, 203, 255, 0.5));
      filter: drop-shadow(0px 0px 5px white);
      
      .text {
        color: $primary-light;
        font-weight: 600;
        margin: 0;
        border-radius: 10px;
      }
    }
    
    .stats-button:hover {
      border-radius: 10px;
      background: radial-gradient(rgb(51, 139, 240, 0.8), rgb(51, 139, 240, 0.8));
      transition: 0.6s ease-in-out;
      filter: none;
    }
  }

  .content-container {
    display: grid;
    grid-template-columns: 1fr minmax(300px, 1fr);

    grid-area: content;
  }

  .side {
    display: flex;
    flex-direction: column;
    align-items: center;

    grid-area: side;
    width: 100%;
    margin-top: 20px;
    z-index: 1;

    .stats-button:hover {
      background-color: $primary400;
    }
  }

  .footer {
    grid-area: footer;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .header-container {
    margin-top: 5px;
    padding: 20px 0 20px 0;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .side {
      margin-top: 0;
      margin-bottom: 50px;
    }

    .content-container {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .footer {
      margin: 20px 0 50px 0;
      grid-area: footer;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@import "../../App.scss";

.settings-container {
    display: grid;
    grid-template:
      "header header header" 125px
      "content content content" minmax(250px, 1fr)
      "footer footer footer" 125px
      / 1fr minmax(300px, 1fr) minmax(400px, 1fr)
    ;

    min-height: 100vh;
    width: 100vw;

    .content-container {
        grid-area: content;
        place-items: center;
        place-content: center;
        display: flex;

        .settings-content-container {
            position: relative;
            display: flex;
            flex-direction: column; 
            z-index: 1;

            height: 450px;
            width: 500px;

            border-radius: 10px;


            .setting-header {
                align-self: center;
                color: $primary-light;
                font-size: $header-text-size; 
                font-weight: 500;
                margin: 0 0 5px 0;
            }

            .settings-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 0 5px 0;
            }

            .settings-text {
                font-weight: 500;
                color: $primary-light;
                font-size: $content-text-size; 
                margin: 0 0 0 0;
            }

            .larger {
                font-size: $content-text-size-1;
            }

            .storage-option-radio-button {
                height: 25px;
                width: 25px;
                border: 2px solid $primary200;
                border-radius: 50%;
                margin: 0 5px 0 10px;
                cursor: pointer;
            }

            .storage-option-radio-button-complete {
                height: 25px;
                width: 25px;
                border: 2px solid $primary200;
                background-color: $primary200;
                border-radius: 50%;
                margin: 0 5px 0 10px;
                cursor: pointer;
            }

            .operator-button {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 35px;
                width: 35px;
                background-color: $primary-light;
                border-radius: 50%;
                cursor: pointer;

                .operator-icon {
                    height: 25px;
                    width: 25px;
                    fill: none;
                    stroke: $primary800;
                    stroke-width: 3;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                }
            }

            .operator-button:hover {
                background-color: $primary800;

                .operator-icon {
                    stroke: $primary-light;
                }
            }

            .value-display-box {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 40px;
                width: 200px;
            }

            .save-button {
                margin-top: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                height: 30px;
                width: 100px;
                background-color: $primary800;
                border-radius: 20px;

                box-shadow: 0px 0px 6px 1px $primary200;
                cursor: pointer;
                transition: 0.3s linear;

                .button-text {
                    margin: 0;
                    font-weight: 500;
                    color: $primary-light;
                }
            }

            .save-button:hover {
                transform: scale(1.1);
            }

            .centered {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .footer {
        grid-area: footer;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
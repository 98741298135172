@import "../../App.scss";

$time: 40s;

.blob {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 48% 52% 68% 32% / 42% 28% 72% 58%;
    background: linear-gradient(to bottom right, $analogous200, $primary600, $analogous200, $primary600);
    background-size: 100% 100%;
  
    .blob-inside {
        width: 280px;
        height: 280px;
        content: '';
        position: relative;
        background-image: url(../../assets/images/IMG_0682.jpeg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        mix-blend-mode: multiply;
    }
  
    .blob-text-container {
      display: flex;
      flex-direction: column;
      width: 220px;
  
      justify-content: center;
      align-items: center;
  
  
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      .blob-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
  
      .blob-text-title {
        font-weight: 600;
        font-size: $content-text-size;
    
        color: $secondary-light;
        mix-blend-mode: soft-light;
  
        cursor: pointer;
      }
  
      .blob-text-subtitle {
        font-size: $subtitle-text-size;
        color: $secondary-light;
  
        cursor: pointer;
      }
    }

    .third-party-music-button {
      .button-text {
        color: $secondary-light;
        font-size: $subtitle-text-size;
      }

      margin: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    
      height: 30px;
      width: 170px;
      border-radius: 8px;
  
      background-color: $primary700;
      mix-blend-mode: exclusion;
      opacity: 0.8;
  
      filter: drop-shadow(2px 2px 10px $primary900);
    }
  
    .new-song-button {
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    
      height: 30px;
      width: 80px;
      border-radius: 8px;
  
      background-color: $primary700;
      mix-blend-mode: exclusion;
      opacity: 0.8;
  
      filter: drop-shadow(2px 2px 10px $primary900);
  
      .button-text {
        color: $secondary-light;
        font-size: $subtitle-text-size;
      }
    }
  
    .new-song-button:hover {
      opacity: 1;
      cursor: pointer;
    }

    .third-party-music-button:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

@keyframes blobber {
    0%  { border-radius: 48% 52% 68% 32% / 42% 28% 72% 58%; }
    5%  { border-radius: 48% 52% 41% 59% / 66% 37% 63% 34%; }
    10% { border-radius: 69% 31% 71% 29% / 67% 31% 69% 33%; }
    15% { border-radius: 60% 40% 71% 29% / 63% 65% 35% 37%; }
    20% { border-radius: 61% 39% 51% 49% / 57% 58% 42% 43%; }
    25% { border-radius: 66% 34% 25% 75% / 47% 30% 70% 53%; }
    30% { border-radius: 32% 68% 38% 62% / 65% 60% 40% 35%; }
    35% { border-radius: 63% 37% 41% 59% / 35% 38% 62% 65%; }
    40% { border-radius: 57% 43% 49% 51% / 55% 71% 29% 45%; }
    45% { border-radius: 47% 53% 34% 66% / 65% 36% 64% 35%; }
    50% { border-radius: 44% 56% 32% 68% / 69% 26% 74% 31%; }
    55% { border-radius: 28% 72% 37% 63% / 71% 44% 56% 29%; }    
    60% { border-radius: 38% 62% 35% 65% / 74% 53% 47% 26%; }
    65% { border-radius: 73% 27% 46% 54% / 54% 47% 53% 46%; }
    70% { border-radius: 75% 25% 47% 53% / 49% 53% 47% 51%; }
    75% { border-radius: 62% 38% 43% 57% / 55% 60% 40% 45%; }
    80% { border-radius: 41% 59% 65% 35% / 73% 50% 50% 27%; }
    85% { border-radius: 55% 45% 57% 43% / 73% 61% 39% 27%; }
    90% { border-radius: 74% 26% 33% 67% / 40% 65% 35% 60%; }
    95% { border-radius: 58% 42% 57% 43% / 53% 45% 55% 47%; }
    100%{ border-radius: 48% 52% 68% 32% / 42% 28% 72% 58%; }
  }
  
  @keyframes cobler {
  0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 0%;
    }
  }
  
  @keyframes wobbler {
    0%  {
        transform: rotateZ(8deg);
    }
    100% {
        transform: rotateZ(-8deg);
    }
  }
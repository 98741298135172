@import "../../App.scss";

.music-player-container {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.music-player-icon-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 168px;
    margin: 0 20px 0 20px;
    z-index: 1;

    .music-player-icon {
      width: $music-player-icon-size;
      height: $music-player-icon-size;
      transition: 0.2s linear;
    }

    .music-player-icon:hover {
      cursor: pointer;
      stroke: $primary300;
      fill: $primary300;
    }
}

.volume-slider-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 20px 0 20px;
    height: 48px;
    width: 168px;
    z-index: 1;

    .volume-slider {
        appearance: none;
        width: 100%;
        height: 12px;
        border-radius: 8px;
        background: $secondary-light;
        outline: none;
        opacity: 0.8;
        transition: opacity 0.2s;
    }

    .volume-slider:hover {
        opacity: 1;
    }

    .volume-slider::-webkit-slider-thumb {
        appearance: none;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        filter: drop-shadow(2px 2px 10px $primary900);
        background: $primary500;
        background-image: url('../../assets/icons/headphones.svg');
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
  }
}
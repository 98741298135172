@import "../../App.scss";

.dialog-mask {
  position: fixed;
  
  background: none;
  opacity: 0.925;

  height: 100%;
  width: 100%;

  min-height: 500px;
  min-width: 700px;

  z-index: 3;
}
  
  .dialog-container {
    position: absolute;
    display: grid;
    grid-template:
      "dialog-header dialog-header" 50px
      "dialog-content dialog-content" auto 
      "dialog-footer dialog-footer" 50px;
    
    margin: auto auto auto auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  
    border-radius: 20px;
  
    text-align: center;
  
    min-height: 500px;
    min-width: 500px;
    height: 500px;
    width: 500px;
    z-index: 3;
  
    .dialog-header {
      display: flex;
      align-items: center;
      grid-area: dialog-header;
  
      .dialog-exit-icon {
        width: 48px;
        height: 48px;
        cursor: pointer;
      }

      .dialog-exit-icon:hover {
        fill: $primary300;
        stroke: $primary800;
      }
  
      .dialog-header-text {
        margin-left: 30px;
        font-family: $font-family;
        color: $secondary-light;
        font-size: $header-text-size;
        font-weight: 400;
      }
    }
  
    .dialog-content {
      grid-area: dialog-content;
      display: flex;
      flex-direction: column;

      .music-card-row:hover {
        background-color: hsl(228, 64%, 48%, 0.5);
        border-radius: 20px;
      }
  
      .music-card-row {
        display: flex;
        align-items: center;
        padding: 5px 5px 5px 5px;
        cursor: pointer;
  
        .music-card-background {
          margin-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
  
          height: 80px;
          width: 80px;
          border-radius: 50%;
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%, 0.6));
          transition: 0.2s linear;
          cursor: pointer;
        }

        .music-card-background:hover {
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
        }
  
        .jazz {
          background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url("../../assets/images/Jazz.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }

        .jazz-selected {
          background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Jazz.jpg");
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
          background-repeat: no-repeat;
          background-size: cover;
        }
  
        .lofi {
          background:  radial-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("../../assets/images/Lofi.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }

        .lofi-selected {
          background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Lofi.jpg");
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
          background-repeat: no-repeat;
          background-size: cover;
        }
  
        .nature {
          background:  radial-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("../../assets/images/Nature.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }

        .nature-selected {
          background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Nature.jpg");
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
          background-repeat: no-repeat;
          background-size: cover;
        }
  
        .classical {
          background:  radial-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("../../assets/images/Classical.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }

        .classical-selected {
          background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Classical.jpg");
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
          background-repeat: no-repeat;
          background-size: cover;
        }
  
        .ambient {
          background:  radial-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("../../assets/images/Cafe.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }

        .ambient-selected {
          background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Cafe.jpg");
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
          background-repeat: no-repeat;
          background-size: cover;
        }
  
        .soundtrack {
          background:  radial-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("../../assets/images/Soundtrack.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: right;
        }

        .soundtrack-selected {
          background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../assets/images/Soundtrack.jpg");
          filter: drop-shadow(0px 0px 3px hsl(334, 100%, 94%));
          background-repeat: no-repeat;
          background-size: cover;
          background-position: right;
        }

        .music-card-text-column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .music-card-text {
            font-size: $header-text-size;
            font-weight: 600;
    
            color: $secondary-light;
            margin: 35px 0 5px 0;
            line-height: 0;
          }

          .music-card-text-subtitle {
            font-size: $content-text-size;
            font-weight: 600;
            color: $grey-primary;
            line-height: 20px;
          }
        }  
      }
    }
  
    .dialog-footer {
      grid-area: dialog-footer;
    }
  }
// FONTS
$font-family: "Avenir Next", "Helvetica Neue", "Helvetica";

// FONT SIZES
$text-size-small: 14px;
$subtitle-text-size: 16px;
$subtitle-text-size-1: 18px;
$content-text-size: 20px;
$content-text-size-1: 24px;
$content-text-size-2: 28px;
$header-text-size: 32px;
$header-text-size-1: 48px;
$header-text-size-2: 72px;
$header-text-size-3: 96px;

// ICON SIZES
$music-player-icon-size: 32px; 
$pomodoro-icon-size: 40px;
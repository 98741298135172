@import "../../App.scss";

.to-do-container {
  display: flex;
  flex-direction: column;
  width: 90%;

  .to-do-items-container-inner {
    padding: 5px 10px 0 10px;
    margin: 0px 0 0 0;
    background-color: $primary-light;
    border-radius: 10px;
    box-shadow: 0px 4px 8px -3px black;

    .to-do-header-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin: 0 0 10px 0;

      .to-do-header-left {
        font-size: $subtitle-text-size;
        color: $black;
        margin: 0;
      }

      .to-do-header-right {
        font-size: $subtitle-text-size;
        color: $black;
        margin: 0 0 0 auto;
        opacity: 0.6;
        cursor: pointer;
      }

      .to-do-header-right:hover {
        font-weight: 500;
      }

      .to-do-header-icon {
        position: relative;
        width: 20px;
        height: 20px;
        fill: none;
        stroke: black;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;

        margin-left: auto;
      }
    }

    .item-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 3px 0;

      .radio-button {
        height: 16px;
        width: 16px;
        border: 0.4pt solid $primary500;
        border-radius: 50%;
        margin: 0 3px 0 0;
      }

      .complete {
        background-color: $primary400;
      }

      .item-text {
        font-size: $text-size-small;
        color: $black;
        font-weight: 400;
        appearance: none;
        background: transparent;
        outline: none;
        border: none;

        width: 100%;
      }
    }

    .add-item-row {
      appearance: none;
      border: none;
      border-color: transparent;
      background-color: transparent;
      font-size: $subtitle-text-size-1;
      font-weight: 500;

      width: 95%;
    }

    .add-item-row:focus {
      outline: none;
    }
  }
}
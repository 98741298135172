@import "../../App.scss";

::-webkit-scrollbar-corner {
    background: rgba(0,0,0,.05);
}

::-webkit-scrollbar {
    background: rgba(0,0,0,.05);
}

::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.3);
}

.apple-music-dialog-mask {
    position: fixed;
    
    background: none;
    opacity: 0.925;
  
    height: 100%;
    width: 100%;
  
    min-height: 500px;
    min-width: 700px;
  
    z-index: 3;
  }

.apple-music-dialog-container {
    position: absolute;
    display: grid;
    grid-template:
        "dialog-header dialog-header" 50px
        "dialog-content dialog-content" auto 
        "dialog-footer dialog-footer" 50px;

    margin: auto auto auto auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    border-radius: 20px;

    text-align: center;

    min-height: 500px;
    min-width: 500px;
    height: 500px;
    width: 500px;
    z-index: 3;

    .apple-music-dialog-header {
        display: flex;
        align-items: center;
        grid-area: dialog-header;

        .apple-music-dialog-exit-icon {
            width: 48px;
            height: 48px;
            cursor: pointer;
          }
    
          .apple-music-dialog-exit-icon:hover {
            fill: $primary300;
            stroke: $primary800;
          }

          .dialog-header-text {
              color: $primary-light;
          }
    }

    .apple-music-dialog-content {
        grid-area: dialog-content;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;


        .apple-music-single-playlist-container {
            .playlist-text {
                color: $primary-light;
                margin: 0;
                padding: 5px;
                height: 24px;
            }

            .playlist-text:hover {
                background-color: hsl(228, 64%, 48%, 0.5);
                border-radius: 20px;
                cursor: pointer;
            }

            .selected {
                background-color: hsl(228, 64%, 48%, 0.5);
                border-radius: 20px; 
            }
        }
    }

    .apple-music-dialog-footer {
        grid-area: dialog-footer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;

        .apple-music-dialog-button {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            height: 30px;
            width: 100px;
            background-color: $primary800;
            border-radius: 20px;

            box-shadow: 0px 0px 6px 1px $primary200;
            cursor: pointer;
            transition: 0.3s linear;

            .button-text {
                margin: 0;
                font-weight: 500;
                color: $primary-light;
            }
        }

        .cancel {
            background-color: hsl(359, 48%, 47%);
        }

        .logout {
            background-color: $dark-grey-2;
        }

        .apple-music-dialog-button:hover {
            transform: scale(1.1);
        }
    }
}

